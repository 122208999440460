<template>
  <div class="game-container">
    <MagnifierGame
      :optionList="optionList"
      :bgImg="bgImg"
      :lessonNo="27"
      :title="title"
    />
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-background.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-animals-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-animals-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-animals-3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-animals-4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-27/book-animals-5.svg"),
        },
      ],
      title: {
        pinyin: "Zhè shì niǎo.",
        hanzi: "这是鸟。",
      },
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
