<template>
  <div class="wordscard-container layout-border">
    <WordImgCards
      :wordCardList="wordCardList"
      @changeTheImg="handleChangeDisplay"
      :titleInfo="titleInfo"
    />
  </div>
</template>
<script>
import WordImgCards from "@/components/Course/CoursePage/WordImgCards";

export default {
  components: {
    WordImgCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          pinyin: "Zhè shì gǒu.",
          hanzi: "这是狗。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/dog.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/dog.svg`),
          Showing:true,
          showType:1
        },
        {
          bgColor: "redColor",
          pinyin: "Zhè shì māo.",
          hanzi: "这是猫。",
           image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat.svg`),
          Showing:true,
          showType:1

        },
        {
          bgColor: "redColor",
          pinyin: "Zhè shì māo hé gǒu.",
          hanzi: "这是猫和狗。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat-dog.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat-dog.svg`),
          Showing:true,
          showType:1
        },
        {
          bgColor: "redColor",
          pinyin: "Zhè shì niǎo.",
          hanzi: "这是鸟。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/bird.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/bird.svg`),
          Showing:true,
          showType:1

        },
        {
          bgColor: "redColor",
          pinyin: "Zhè shì yú.",
          hanzi: "这是鱼。",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/fish.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/fish.svg`),
          Showing:true,
          showType:1
        },
      ],
       titleInfo:{
        pinyin:"Zhè shì shénme?",
        hanzi:"这是什么？"
      }
    };
  },
  methods: {
    handleChangeDisplay(index) {
      this.wordCardList[index].Showing = !this.wordCardList[index].Showing;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
