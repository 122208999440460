<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "de",
              hanzi: "的",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "gǒu",
              hanzi: "狗",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "niǎo",
              hanzi: "鸟",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "yú",
              hanzi: "鱼",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
             {
              pinyin: "bù",
              hanzi: "不",
              symbol: "",
              group: 0,
              index: 7,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zhīshidiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: '\xa0',
                hanzi: "1",
                symbol: "",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: ".",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 2,
              },
              
              
              {
                pinyin: "de",
                hanzi: "“的”",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
             
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 7,
              },
              {
                pinyin: "biǎoshì",
                hanzi: "表示",
                symbol: "",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 0,
              },
              {
                pinyin: "suǒshǔ",
                hanzi: "所属",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              
            ],
            [
              {
                pinyin: "\xa0",
                hanzi: "2",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: ".",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              
              
              {
                pinyin: "bù",
                hanzi: "“不”",
                symbol: "",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 0,
              },
             
             
              {
                pinyin: "biǎoshì",
                hanzi: "表示",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "fǒudìng",
                hanzi: "否定",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "Zhè",
                hanzi: "这",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "bù shì",
                hanzi: "不是",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "wǒ de",
                hanzi: "我的",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "yú",
                hanzi: "鱼",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 2,
                index: 6,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "jiǎndān de",
              hanzi: "简单地",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "jièshào",
              hanzi: "介绍",
              group: 2,
              index: 3,
            },
            {
              pinyin: "suǒshǔ guānxì",
              hanzi: "所属关系",
              group: 2,
              index: 4,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>