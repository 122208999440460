<template>
  <div class="game-container">
    <DragToGroupDressGame
      :bgImgList="bgImgList"
      :bgImgArr="bgImgArr"
      :optionImgList="optionImgList"
      :lessonNO="27"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import DragToGroupDressGame from "@/components/Course/GamePage/DragToGroupDressGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "Zhè shì shéi de?",
        hanzi: "这是谁的？",
      },
      bgImgArr: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/background-1-girl.svg"),
          pinyin: "Zhè shì Xiàxia de.",
          hanzi: "这是夏夏的。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/background-2-boy.svg"),
          pinyin: "Zhè shì Dōngdōng de.",
          hanzi: "这是冬冬的。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/background-3-man.svg"),
          pinyin: "Zhè shì bàba de.",
          hanzi: "这是爸爸的。",
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/background-4-woman.svg"),
          pinyin: "Zhè shì māma de.",
          hanzi: "这是妈妈的。",
        },
      ],
      bgImgList: [
        [
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-dress-done.svg"),
            id: 1,
            index: 1,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-shoes-done.svg"),
            id: 2,
            index: 2,
          },
        ],
        [
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shirt-done.svg"),
            id: 1,
            index: 3,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-pants-done.svg"),
            id: 2,
            index: 4,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shoes-done.svg"),
            id: 3,
            index: 5,
          },
        ],
        [
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-jacket-done.svg"),
            id: 1,
            index: 6,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-trousers-done.svg"),
            id: 2,
            index: 7,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-shoes-done.svg"),
            id: 3,
            index: 8,
          },
        ],
        [
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-dress-done.svg"),
            id: 1,
            index: 9,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-bag-done.svg"),
            id: 2,
            index: 10,
          },
          {
            bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-shoes-done.svg"),
            id: 3,
            index: 11,
          },
        ],
      ],
      optionImgList: [
        [
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-shoes.svg"),
            index: 1,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-pants.svg"),
            index: 2,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shirt.svg"),
            index: 3,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-dress.svg"),
            index: 4,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shoes.svg"),
            index: 5,
          },
        ],
        [
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-shoes.svg"),
            index: 6,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-pants.svg"),
            index: 7,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shirt.svg"),
            index: 8,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/girl-dress.svg"),
            index: 9,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/boy-shoes.svg"),
            index: 10,
          },
        ],

        [
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-dress.svg"),
            index: 11,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-trousers.svg"),
            index: 12,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-shoes.svg"),
            index: 13,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-bag.svg"),
            index: 14,
          },
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-jacket.svg"),
            index: 15,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-shoes.svg"),
            index: 16,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-dress.svg"),
            index: 17,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-trousers.svg"),
            index: 18,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-shoes.svg"),
            index: 19,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-bag.svg"),
            index: 20,
          },
          {
            id: 0,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/man-jacket.svg"),
            index: 21,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-27/woman-shoes.svg"),
            index: 22,
          },
        ],
      ],
    };
  },
  components: {
    DragToGroupDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
