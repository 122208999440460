<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-27.mp3"),
      },
      timeStampList: [7.0, 14.2, 17.3, 21.6, 24.8, 27.6],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Gǒu, niǎo, yú, ",
            chineseWords: "狗、鸟、鱼,",
          },
          {
            id: 2,
            spelling: "Zhè shì nǐ de gǒu.",
            chineseWords: "这是你的狗。",
          },
          {
            id: 3,
            spelling: "Zhè shì wǒ de niǎo.",
            chineseWords: "这是我的鸟。",
          },
          {
            id: 4,
            spelling: "Zhè shì shéi de yú?",
            chineseWords: "这是谁的鱼？",
          },
          {
            id: 5,
            spelling: "Zhè bú shì nǐ de yú.",
            chineseWords: "这不是你的鱼。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
