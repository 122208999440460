<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
      :title="title"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/background-sea.svg"),
      gameIndex:272,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/big.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/big-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/cute.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/cute-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/fat.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/fat-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/small.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/small-found.svg"),
        },
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/grumpy.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/grumpy-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/fish-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-3/fish-found.svg"),
      title:{
        pinyin:"Zhè shì yǔ.",
        hanzi:"这是鱼。"
      }
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>