<template>
  <div class="wordscard-container layout-border">
    <WordImgCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
      :lessonNum="11"
      :titleInfo="titleInfo"
    />
  </div>
</template>
<script>
import WordImgCards from "@/components/Course/CoursePage/WordImgCards";

export default {
  components: {
    WordImgCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          // currentNum: 1,
          pinyin: "Dōngdōng de gǒu",
          hanzi: "冬冬的狗",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/dog.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/dog-dongdong.svg`),
          Showing:true,
          showType:1
        },
        {
          bgColor: "redColor",
          // currentNum: 3,
          pinyin: "Tútu de māo",
          hanzi: "图图的猫",
           image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/cat-tutu.svg`),
          Showing:true,
          showType:1

        },
        {
          bgColor: "redColor",
          // currentNum: 5,
          pinyin: "Tútu jiějie de niǎo",
          hanzi: "图图姐姐的鸟",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/bird.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/bird-sister.svg`),
          Showing:true,
          showType:1

        },
        {
          bgColor: "redColor",
          // currentNum: 5,
          pinyin: "Tútu bàba de yú",
          hanzi: "图图爸爸的鱼",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/fish.svg`),
          imgShow: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-27/fish-dad.svg`),
          Showing:true,
          showType:1
        },
      ],
       titleInfo:{
        pinyin:"de",
        hanzi:"的"
      }
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
