<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-08-25 11:01:29
 * @LastEditors: shmily
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter4\Lesson27\9_PracticePageTwo.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
      :title="title"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/background-park.svg"),
      gameIndex:27,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-1.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-1-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-2.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-2-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-3.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-3-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-4.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-4-found.svg"),
        },
         {
          notFind: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-5.svg"),
          find: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-5-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G63-find-images/lesson-27-1/dog-found.svg"),
      title:{
        pinyin:"Zhè shì gǒu.",
        hanzi:"这是狗。"
      }
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>